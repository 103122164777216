import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'

export class ServiceDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    code?: Nullable<string>;
    version?: Nullable<string>;
    release_date?: Nullable<string>;
    priority?: Nullable<number>;

    constructor({ id, guid, name, code, version, release_date, priority}: {id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>, code?: Nullable<string>, version?: Nullable<string>, release_date?: Nullable<string>, priority?: Nullable<number>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.code = code;
        this.version = version;
        this.release_date = release_date;
        this.priority = priority;
    }
}

export default class Service extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private code: string;
    private version: Nullable<string>;
    private release_date: Nullable<string>;
    private priority: number;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        code: string,
        version: Nullable<string>,
        release_date: Nullable<string>,
        priority: number
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.code = code;
        this.version = version;
        this.release_date = release_date;
        this.priority = priority;
    }

    static create(dto: ServiceDTO): Service
    {
        return new Service(
            dto.id,
            dto.guid,
            dto.name,
            dto.code,
            dto.version,
            dto.release_date,
            dto.priority
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setCode(code: string): void
    {
        this.code = code;
    }

    getCode(): string
    {
        return this.code;
    }

    setVersion(version: string): void
    {
        this.version = version;
    }

    getVersion(): string
    {
        return this.version;
    }

    setReleaseDate(release_date: string): void
    {
        this.release_date = release_date;
    }

    getReleaseDate(): string
    {
        return this.release_date;
    }

    setPriority(priority: number): void
    {
        this.priority = priority;
    }

    getPriority(): number
    {
        return this.priority;
    }
}
